import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import * as Theme from "../../Theme";
import Preview from "rev.sdk.js/Components/RichTextPreview";
import useDimension from "../../hooks/use-dimension";
import { Element } from "react-scroll";
import { navigate } from "gatsby";

function Faq({ record, defaultOpen = false }) {
  const { rwd } = useDimension();
  const [open, setOpen] = useState(defaultOpen);

  const handleTitleOnClick = () => {
    if (record.title === "條款聲明 : 服務使用條款") {
      navigate("/terms");
      return;
    }
    if (record.title === "隱私權政策") {
      navigate("/privacy");
      return;
    }
    setOpen(prev => !prev);
  };

  const renderIcon = () => {
    if (
      record.title === "條款聲明 : 服務使用條款" ||
      record.title === "隱私權政策"
    ) {
      return (
        <img
          src="../../images/about/arrow.png"
          style={{ width: "14px", objectFit: "contain" }}
          alt=""
        />
      );
    }
    if (open) {
      return (
        <img
          src="../../images/faq-page/minus.png"
          style={{ width: "14px", objectFit: "contain" }}
          alt=""
        />
      );
    }
    return (
      <img
        src="../../images/faq-page/add.png"
        style={{ width: "14px", objectFit: "contain" }}
        alt=""
      />
    );
  };

  return (
    <Wrapper>
      <Title open={open} onClick={() => handleTitleOnClick()}>
        <div
          style={{
            marginRight: 18,
            backgroundColor: "#707070",
            width: 8,
            height: 8,
          }}
        />

        <div
          style={{
            flex: 1,
            fontSize: 16,
          }}
        >
          {record.title}
        </div>
        <div>{renderIcon()}</div>
      </Title>

      <Content rwd={rwd} className={open ? "open" : ""}>
        {open && <Preview content={record.content} />}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  animation: fadein 0.6s ease-out;
`;

const Title = styled.div`
  padding: 12px 20px;
  background-color: ${props =>
    props.open ? Theme.colors.lightGreen : "#f0f0f0"};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  transition: background-color 0.4s ease-out;
`;

const Content = styled.div`
  padding: ${props => (props.rwd === "mobile" ? "10px" : "10px 48px")};
  overflow: hidden;

  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.4s ease-out;

  &.open {
    transform: scaleY(1);
  }
`;

export default function FaqItemGroup(props) {
  const { records, faqItemOpenMgr } = props;

  function shouldOpen(record) {
    return faqItemOpenMgr.getItemOpenStatus(
      record.label.join("__") + "__" + record.title
    );
  }

  return records.map((record, idx) => (
    <Element name={record.label.join("__") + "__" + record.title} key={idx}>
      <Faq record={record} defaultOpen={shouldOpen(record)} />
    </Element>
  ));
}
