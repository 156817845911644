import React from "react";

class FaqItemOpenMgr {
  constructor() {
    this.itemOpenStatus = {};
  }

  setForceUpdate(forceUpdate) {
    !this.forceUpdate && (this.forceUpdate = forceUpdate);
  }

  setItemOpenByDisplayName(name, isOpen) {
    Object.keys(this.itemOpenStatus).forEach(
      k => (this.itemOpenStatus[k] = false)
    );
    this.itemOpenStatus[name] = isOpen;
    this.forceUpdate();

    console.log("itemOpenStatus", this.itemOpenStatus);
  }

  getItemOpenStatus(name) {
    return !!this.itemOpenStatus[name];
  }
}

export { FaqItemOpenMgr };
