import constants from "./constants";

const ApiFactory = () => {
  const serviceHost = "";
  const apiHost = constants.revJsonStorageHost;
  const client = constants.revClientId;

  return {
    getService: async () => {
      const resp = await fetch(`${serviceHost}?client_id=${client}`);

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      let services = await resp.json();

      // copy jsonstore to articles
      let service = services.find(sv => sv.service_name === "jstorage");
      service = {
        ...service,
        service_name: "articles",
        display_name: "Revtel-Article-Service",
      };

      apiHost = `${service.domain}/${service.latest}`;
    },

    getBlogLabels: async () => {
      let url = `${apiHost}/document/categories/find-one?client_id=${client}`;

      let data = {
        query: {
          name: "articles",
        },
      };

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },

    getFaqLabels: async () => {
      let url = `${apiHost}/document/categories/find-one?client_id=${client}`;

      let data = {
        query: {
          name: "faqs",
        },
      };

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },

    getBlogs: async ({ documentName, paging, ...params }) => {
      let url = `${apiHost}/document/${documentName}/find?client_id=${client}`;

      let data = {
        query: params,
        sorting: ["-updated"],
        paging,
      };

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },

    getBlog: async ({ documentName, ...params }) => {
      let url = `${apiHost}/document/${documentName}/find-one?client_id=${client}`;

      let data = {
        query: params,
      };

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },
  };
};

export default ApiFactory;
