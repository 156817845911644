import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Collapse, Row, Col } from "antd";
import styled from "styled-components";
import Constants from "../../constants";
import useDimension from "../../hooks/use-dimension";
import { Context } from "../../AppContext";
import StaticImage from "../../Components/StaticImage";

import * as Theme from "../../Theme";
import ApiBlog from "../../ApiBlog";
import FaqItemGroup from "./FaqItem";

import { FaqItemOpenMgr } from "./FaqPageUtil";

import * as Scroll from "react-scroll";

const iconArr = {
  購物指南: "/images/faq-page/faq-1.svg",
  常見問題: "/images/faq-page/faq-2.svg",
  會員須知: "/images/faq-page/faq-3.svg",
  印刷須知: "/images/faq-page/faq-4.svg",
  配送說明: "/images/faq-page/faq-5.svg",
  聯絡資訊: "/images/faq-page/faq-6.svg",
  條款聲明: "/images/faq-page/faq-7.svg",
  其他問題: "/images/faq-page/faq-8.svg",
};

const DOCUMENT_NAME = "Article_Default";

const faqItemOpenMgr = new FaqItemOpenMgr();

function FaqPage(props) {
  const { rwd } = useDimension();
  const mobile = rwd === "pad" || rwd === "mobile";
  const app = useContext(Context);

  const api = useRef(new ApiBlog()).current;
  const [labels, setLabels] = useState([]);
  const [selected, setSelected] = useState();
  const selectedGroup = labels.find(l => l.name === selected)?.children || [];
  const [selectedSub, setSelectedSub] = useState();
  const [records, setRecords] = useState([]);

  const [, forceUpdate] = useState();
  faqItemOpenMgr.setForceUpdate(forceUpdate);
  React.useEffect(() => {
    if (typeof window != undefined && window.location.search) {
      const params = window.location.search
        .substring(1) // remove first '?'
        .split("&")
        .reduce((ret, curr) => {
          ret[curr.split("=")[0]] = decodeURI(curr.split("=")[1]);
          return ret;
        }, {});

      if (params["major"] && params["sub"] && params["item"]) {
        let _major = labels.find(l => l.display === decodeURI(params["major"]));
        if (_major) {
          setSelected(_major.name);
          if (
            (_major.children || []).find(
              c => c.display === decodeURI(params["sub"])
            )
          ) {
            setSelectedSub(
              (_major.children || []).find(
                c => c.display === decodeURI(params["sub"])
              ).name
            );
          }
        }

        let targetElement =
          "FAQ" +
          "__" +
          decodeURI(params["major"]) +
          "__" +
          decodeURI(params["sub"]) +
          "__" +
          decodeURI(params["item"]);
        faqItemOpenMgr.setItemOpenByDisplayName(targetElement, true);

        // do auto scroll to element
        setTimeout(() => {
          Scroll.scroller.scrollTo(targetElement, {
            duration: 1500,
            delay: 100,
            smooth: "easeInOutQuint",
            offset: -100, // Scrolls to element + 50 pixels down the page
          });
        }, 500);
      }
    }
  }, [labels]);

  const getRecords = useCallback(async label => {
    app.actions.setLoading(true);
    setRecords([]);

    try {
      const filters = [
        { label: "FAQ" },
        { label },
        {
          label: { $not: { $regex: "draft" } },
        },
      ];

      let resp = await api.getBlogs({
        documentName: DOCUMENT_NAME,
        $and: filters,
      });

      setRecords(resp);
    } catch (ex) {
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, []);

  const handleCollapseOnChange = idx => {
    setSelectedSub(idx ? selectedGroup[idx].name : null);
  };

  useEffect(() => {
    if (selectedSub) {
      getRecords(selectedSub);
    }
  }, [selectedSub]);

  // initial
  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);

      try {
        let resp = await api.getFaqLabels();
        const _labels = resp.children;
        setLabels(_labels);

        if (_labels.length > 0) {
          setSelected(_labels[0].name);
        }
      } catch (err) {
        console.warn(err);
      }
      app.actions.setLoading(false);
    })();
  }, []);

  return (
    <Wrapper isMobile={mobile}>
      <div className="banner">
        <div className="img-container">
          <StaticImage
            filename="faq_banner.jpg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <div className="banner-tag">
            <div>FAQ / 常見問題</div>
          </div>
        </div>
      </div>

      <div style={{ height: 40 }} />

      <div className="center-content">
        <div className={mobile ? `rwd-wrapper` : ""}>
          <Row
            gutter={[
              { xs: 6, sm: 70, lg: 40 },
              { xs: 30, sm: 30 },
            ]}
            justify="space-between"
          >
            {labels.map((l, idx) => (
              <Col xs={6} lg={3} key={idx}>
                <LabelItem
                  rwd={rwd}
                  isMobile={mobile}
                  isSelected={selected == l.name}
                  onClick={() => {
                    setSelected(l.name);
                    setSelectedSub(
                      l.children.length > 0 ? l.children[0].name : null
                    );
                  }}
                >
                  <img
                    src={iconArr[l.name]}
                    width={rwd === "mobile" ? "30" : "45"}
                    height={rwd === "mobile" ? "30" : "45"}
                  />
                  <div>{l.display}</div>
                </LabelItem>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className="center-content">
        <div className="header">
          <div className="title">{selected}</div>
          <div className="subtitle">常見問題 / {selected}</div>
        </div>
        <div className="collapse-list">
          <div
            id="direct-jump-with-url-query"
            name="direct-jump-with-url-query"
          >
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return (
                    <img
                      src="/images/faq-page/close.png"
                      style={{
                        width: "15px",
                        height: "12px",
                        objectFit: "contain",
                        marginRight: 4,
                      }}
                    />
                  );
                } else {
                  return (
                    <img
                      src="/images/faq-page/open.png"
                      style={{
                        width: "15px",
                        height: "12px",
                        objectFit: "contain",
                        marginRight: 4,
                      }}
                    />
                  );
                }
              }}
              expandIconPosition="right"
              ghost="true"
              accordion={true}
              style={{ borderTop: `0.5px solid ${Theme.colors.lighterGrey}` }}
              activeKey={
                selectedGroup.findIndex(s => s.name === selectedSub) !== -1
                  ? selectedGroup.findIndex(s => s.name === selectedSub)
                  : null
              }
              onChange={idx => handleCollapseOnChange(idx)}
            >
              {selectedGroup.map((g, idx) => (
                <ArticleRow
                  isMobile={mobile}
                  header={
                    <div style={{ color: Theme.colors.brown, fontSize: 18 }}>
                      {g.display}
                    </div>
                  }
                  key={idx}
                >
                  {selectedSub === g.name && (
                    <FaqItemGroup
                      records={records}
                      faqItemOpenMgr={faqItemOpenMgr}
                    />
                  )}
                </ArticleRow>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    background-color: #f6f6f6;
    & > .img-container {
      position: relative;
      height: ${props => (props.isMobile ? "250px" : "270px")};
      max-width: ${Theme.centerContentMaxWidth};
      margin: 0px auto;
      & > .banner-tag {
        ${Theme.bannerTagStyle}
        background-color: #3c3c3c;
        & > div {
          color: #ffffff;
        }
      }
    }
  }
  & > .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;
    & .labels-row {
      display: flex;
      justify-content: space-between;
    }
    & > .header {
      & > .title {
        ${Theme.textTitleStyle}
        margin-top: 38px;
        text-align: center;
      }
      & > .subtitle {
        ${Theme.textContentStyle}
        margin-top: 10px;
        text-align: center;
      }
    }
    & > .collapse-list {
      padding: ${props => (props.isMobile ? Theme.rwdPadding.outer : 0)};
      margin: 40px 0px;
    }
    & > .rwd-wrapper {
      max-width: 600px;
      margin: 0 auto;
      padding: ${props =>
        props.isMobile ? Theme.rwdPadding.outer : Theme.rwdPadding.large};
    }
  }
`;

const LabelItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: ${props => (props.isMobile ? "13px" : "18px")};
  height: ${props => {
    if (props.rwd === "desktop") {
      return "120px";
    } else if (props.rwd === "pad") {
      return "120px";
    } else {
      return "80px";
    }
  }};
  min-width: ${props => {
    if (props.rwd === "desktop") {
      return "120px";
    } else if (props.rwd === "pad") {
      return "120px";
    } else {
      return "80px";
    }
  }};
  cursor: pointer;
  background-color: ${props =>
    props.isSelected ? Theme.colors.lightGreen : "#f6f6f6"};

  &:hover {
    background-color: ${Theme.colors.lightGreen};
  }

  & > div {
    color: ${props => (props.isSelected ? "#3c3c3c" : "#707070")};
    font-size: ${props => (props.isMobile ? "12px" : "14px")};
    margin-top: 8px;
  }
`;

const ArticleRow = styled(Collapse.Panel)`
  &.ant-collapse-item {
    padding: 12px 0px;
    border-bottom: 0.5px solid ${Theme.colors.border_grey};
  }
  background-color: #fff;
  padding: ${props => (props.isMobile ? "0px" : "12px")};

  & .ant-collapse-content-box {
    padding: 0;
  }
`;

export default FaqPage;
